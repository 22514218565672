#teethTable {
    overflow: hidden;
    table-layout: fixed;
    border-collapse: collapse;
    box-shadow: 10px 10px 10px #ccc;
    border-radius: 10px;
    white-space: nowrap;
    width: 100em;
    max-width: 95%;
    margin: auto;
    overflow-x: auto;
}

#teethTable thead th {
    background-color: #ccc;
    color: #222;
    padding: 0.5rem;
    border-right: 0.5px solid #ddd;
}

#teethTable th,
#teethTable td {
    text-align: center;
    padding: 0.5rem;
}

#teethTable td:not(:last-child) {
    border-right: 0.5px solid #ddd; /* 각 셀의 오른쪽 테두리 설정 */
}

#teethTable tbody tr td {
    border-bottom: 0.5px solid #ddd; /* 각 셀의 아래쪽 테두리 설정 */
}

#teethTable tbody tr:last-child td {
    border-bottom: none; /* 마지막 행의 셀에 아래쪽 가로선 제거 */
}

#teethTable thead th:first-child {
    border-top-left-radius: 10px;
}

#teethTable thead th:last-child {
    border-top-right-radius: 10px;
}

#teethTable thead tr:last-child th:last-child{
    border-right: none;
}
