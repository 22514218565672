
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');
@import url("https://gcore.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

.btn-outline-pink:hover {
    color: #fff !important;
    background-color: #ff4081 !important;
    border-color: #ff4081 !important;
}

.btn-pink:hover {
    color: #fff !important;
    background-color: #c72058 !important;
    border-color: #c72058 !important;
}

.btn-outline-pink {
    color: #ff4081 !important;
    background-color: #fff !important;
    border-color: #ff4081 !important;
}

.btn-pink {
    color: #fff !important;
    background-color: #ff4081 !important;
    border-color: #ff4081 !important;
}

.fs-0_5 {
    font-size: 5.5rem !important;
}

.fs-6_5 {
    font-size: 0.9rem !important;
}

.text-pink {
    color: #ff4081;
}

.bg-kiosk {
    background-color: #377dff;
}

input[type="checkbox"]:checked + .check-btn {
    border-color: #ff4081 !important;
    border-width: 3px;
}

a {
    text-decoration: none;
    color: #444444;
    cursor: pointer;
}

a:hover {
    text-decoration: none;
    color: #ff4081;
}

.kiosk-input input[type="checkbox"] {
    width: 40px;
    height: 40px;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
}

.kiosk-input  input::-webkit-input-placeholder {
    font-size: 1.8em;
}

.kiosk-input  input:-ms-input-placeholder {
    font-size: 1.8em;
}
.write-input::-webkit-input-placeholder {
    font-size: 40px;
}

.write-input:-ms-input-placeholder {
    font-size: 40px;
}
.write-input{
    font-size: 40px !important;
}

.py-6{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}

.main_pd {
    padding-bottom: 15%;
    padding-top: 3%;
}

.footer-fix {
    width: 100%;
    background-color: #fff;
    border: 1px solid #dee2e6;
    margin: 0;
    margin-top: 50;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
}